$colors: (
  "white": #ffffff,
  "black": #0F172A,
  "pink": #F0899C,
  "blue": #00B5E2,
  "softblue": #F8FBFF,
  "transparent": transparent,
  "gray-1": #657790,
);

// Default Font Size
$base-font-size: 16px;

// Responsive Breakpoint
$br-xxs: 375px;
$br-xx: 425px;
$br-xs: 480px;
$br-mm: 512px;
$br-sm: 576px;
$br-md: 768px;
$br-lg: 992px;
$br-xl: 1140px;
$br-xxl: 1200px;
