@import "../abstracts/variables";
@import "../abstracts/functions";
@import "../abstracts/keyframes";

.section {
}

.wrapper {
  &__tab-category {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    .items {
      margin: 0 1.5rem;
      padding: 1rem 1.3rem;
      border-radius: 8px;
      box-shadow: 0px 4px 64px 0px #23286914;
      background-color: white;
      transition: 0.5s;

      &:hover {
        background-color: color("pink");
        color: white;
      }

      &.active {
        background-color: color("pink");
        color: white;
      }
    }

    @media screen and(max-width: $br-md) {
      margin-left: -1rem;
      margin-right: -1rem;
      .items {
        margin: 0 1rem;
      }
    }
    @media screen and(max-width: $br-mm) {
      flex-wrap: wrap;
      justify-content: flex-start;
      .items {
        margin-bottom: 1rem;
      }
    }
  }
  &__brand-list {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 2rem;
    }

    @media screen and(max-width: 1024px) {
      img {
        margin: 0 1rem;
      }
    }
    @media screen and(max-width: $br-md) {
      flex-wrap: wrap;

      img {
        width: 27%;
        margin-bottom: 1rem;
      }
    }
    @media screen and(max-width: $br-sm) {
      img {
        width: 35%;
      }
    }
  }
  &__drop-wrap {
    .center {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .image__center {
        max-width: 100px;
        max-height: 100px;
        object-fit: contain;
      }
    }
  }
  &__drop-item {
    max-width: 100% !important;
    width: 100%;
    height: 150px !important;
    border-radius: 24px !important;
    min-width: 100% !important;
    border: dashed 2px #e7e7e7 !important;
    svg {
      display: none;
    }

    > div {
      display: none;
    }
  }
  &__card-jobs {
    width: fit-content;
    padding: 1rem 0.5rem;
    border-radius: 10px;
    background-color: #fef3f5;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 258px;

    @media screen and(max-width: $br-xs) {
      width: 100%;
    }

    &.left {
      transform: rotate(-1.11deg);
    }
    &.right {
      transform: rotate(2.25deg);
    }
  }
  &__offsite-wrap {
    @media screen and(max-width: $br-md) {
      overflow: auto;
    }
  }
  &__table-compare-group {
    @media screen and(max-width: $br-md) {
      width: 800px;
    }
    table {
      table-layout: fixed;
      .w-f-first {
        width: 46%;

        @media screen and(max-width: 1024px) {
          width: 30%;
        }
      }
      &.noborder {
        thead {
          tr {
            th {
              border: none;
            }
          }
        }
      }

      thead {
        tr {
          th,
          td {
            border: 1px solid #d4d4d8;
            &:first-child {
              border-left: none;
            }
            &:last-child {
              border-right: none;
            }
          }

          &.first {
            th {
              border: none;
            }
          }

          &.spacing-top {
            th,
            td {
              padding-top: 3rem;
            }
          }
        }
      }

      tbody {
        tr {
          th,
          td {
            border: 1px solid #d4d4d8;

            &:first-child {
              border-left: none;
            }
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }
  &__number-project {
    padding: 3rem 10rem;
    border-radius: 32px;
    background-color: #fef3f5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and(max-width: 1024px) {
      padding: 3rem 5rem;
    }
    @media screen and(max-width: $br-md) {
      padding: 3rem 3rem;
    }
    @media screen and(max-width: $br-sm) {
      flex-wrap: wrap;

      .items {
        width: 100%;
        margin-bottom: 2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__scroll-offsite {
    @media screen and(max-width: 1024px) {
      overflow-x: auto;
      overflow-y: hidden;
      .wrapper__personal-grup {
        width: 1270px;
        height: 337px;
        align-items: center;
      }
    }
  }
  &__side-link {
    padding: 2rem;
    border-radius: 16px;
    background-color: #f8fbff;

    .items {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__team-user {
    transform: rotate(2.5deg);
    &.rg {
      transform: rotate(-2.5deg);
    }
    .bg {
      width: 100%;
      height: 417px;
      border-radius: 12px;
      object-fit: cover;
    }

    .cursor {
      position: absolute;
      left: 100%;
      transform: translateX(-50%);
      bottom: -10rem;
      visibility: hidden;
      opacity: 0;
      z-index: -1;
      transition: 0.5s;
    }

    .desc {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -10rem;
      visibility: hidden;
      opacity: 0;
      transition: 0.5s;

      .arrow {
        margin-bottom: -1rem;
      }

      .box {
        background-color: white;
        padding: 1rem;
        border-radius: 8px;
      }
    }

    &:hover {
      .cursor {
        left: 70%;
        transform: translateX(-50%);
        bottom: 2rem;
        visibility: visible;
        opacity: 1;
        z-index: 1;
      }

      .desc {
        left: 50%;
        transform: translateX(-50%);
        bottom: 3rem;
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__field-input {
    height: 47px;
    padding: 0 1rem;
    box-shadow: none;
    outline-style: none;
    border: 1px solid #ededed;
    border-radius: 0;

    &.textarea {
      height: auto;
      padding: 1rem;
    }

    &:hover {
      box-shadow: none;
      outline-style: none;
      border: 1px solid #ededed;
      border-radius: 0;
    }
    &:active {
      box-shadow: none;
      outline-style: none;
      border: 1px solid #ededed;
      border-radius: 0;
    }
    &:focus {
      box-shadow: none;
      outline-style: none;
      border: 1px solid #ededed;
      border-radius: 0;
    }
  }
  &__contact-wrap {
    padding: 4rem;
    background: #ffffff;
    box-shadow: 0px 4px 64px rgba(35, 40, 105, 0.08);
    border-radius: 10px;

    @media screen and(max-width:  $br-sm) {
      padding: 2rem;
    }
  }
  &__offset-slide {
    width: 1500px;
    @media screen and(max-width:  $br-sm) {
      width: auto;
    }
  }
  &__slider-client {
    margin: 0 -15px;

    .items {
      padding: 0 15px;
    }
  }
  &__client-list {
    display: flex;
    align-items: center;
    @media screen and(max-width:  $br-sm) {
      flex-wrap: wrap;

      .desc {
        width: 100%;
      }
    }
    .user {
      width: 236px;
      height: 337px;
      object-fit: cover;
      border-radius: 24px;

      @media screen and(max-width:  $br-sm) {
        margin-bottom: 1.5rem;
      }
    }
  }
  &__list-service {
    .items {
      padding: 1rem;
      background: #ffffff;
      box-shadow: 0px 4px 64px rgba(35, 40, 105, 0.08);
      border-radius: 8px;
      margin-bottom: 20px;
      transition: 0.5s;

      div {
        svg {
          path {
            transition: 0.5s;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        color: white !important;
        background-color: color("pink");

        div {
          svg {
            path {
              fill: color("white");
              stroke: color("white");
            }
          }
        }
      }

      &:hover {
        color: white !important;
        background-color: color("pink");

        div {
          svg {
            path {
              fill: color("white");
              stroke: color("white");
            }
          }
        }
      }
    }
  }
  &__sosmed-footer {
    .link {
      a {
        margin: 0 10px;
      }
    }
  }
  &__subscribe {
    button {
      padding: 17px 30px;
      height: 50px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    input {
      padding: 4px 1rem;
      width: 100%;
      height: 50px;
      background: #ffffff;
      border: 1px solid #e4e4e7;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      box-shadow: none;
      outline-style: none;

      &:hover {
        background: #ffffff;
        border: 1px solid #e4e4e7;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: none;
        outline-style: none;
      }
      &:active {
        background: #ffffff;
        border: 1px solid #e4e4e7;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: none;
        outline-style: none;
      }
      &:focus {
        background: #ffffff;
        border: 1px solid #e4e4e7;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: none;
        outline-style: none;
      }
    }
  }
  &__contact-us {
    padding: 5.5rem 4rem;
    background-color: color("blue");
    border-radius: 32px;

    @media screen and(max-width:  1024px) {
      padding: 4rem 3rem;
    }
    @media screen and(max-width:  $br-xx) {
      padding: 3rem 1.5rem;
    }
  }
  &__accordion {
    .items {
      .head {
        padding: 8px 0;
        transition: 0.5s;

        .arrow {
          transition: 0.5s;
        }
      }
      .body {
        padding: 8px 0;
        transition: 0.5s;
        max-height: 0;
        overflow: hidden;
      }

      &.active {
        .head {
          padding: 8px 0;
          transition: 0.5s;

          .arrow {
            transform: rotate(-180deg);
            transition: 0.5s;
          }
        }
        .body {
          padding: 8px 0;
          transition: 0.5s;
          max-height: 50rem;
          overflow: visible;
        }
      }
    }
  }
  &__slider-testimonial {
    width: calc(100% + 100px) !important;
    overflow: hidden;
    margin: 0 -15px;

    .slick-list {
      overflow: visible !important;
    }
    .items {
      padding: 0 15px;
      .user__about {
        padding: 1rem;
        background: #ffffff;
        box-shadow: 0px 44px 94px rgba(0, 0, 0, 0.06);
        border-radius: 80px;
        width: 100%;
        max-width: 406px;
        max-height: 86px;

        div {
          .img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }
  }
  &__price-wrap {
    padding: 2rem;
    background-color: color("pink");
    border-radius: 12px;

    &.white {
      background-color: white;
      border: 1px solid #e5eaf1;
    }
  }
  &__choose-line-wrap {
    border: 1.08206px solid #e5eaf1;
    border-radius: 12px;
    .line-dual {
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 80%;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-color: #e5eaf1;
        @media screen and(max-width: $br-sm) {
          display: none;
        }
      }
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 80%;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background-color: #e5eaf1;
        @media screen and(max-width: $br-sm) {
          display: none;
        }
      }
    }
  }
  &__card-choose {
    padding: 2.5rem 1.5rem;
    .icon {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: rgba(240, 137, 156, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__personal-grup {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 27px;
    grid-row-gap: 0px;

    @media screen and(max-width: $br-md) {
      padding: 0 2rem;
    }

    .items {
      > div {
        transform: rotate(2deg);
        transition: 0.5s;
        &.rg {
          transform: rotate(-0.5deg);
        }
        .arrow {
          position: absolute;
          left: 80%;
          transform: translateX(-50%);
          bottom: -5rem;
          opacity: 0;
          z-index: -1;
          transition: 0.8s;
        }
        .info {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -5rem;
          width: 80%;
          opacity: 0;
          z-index: -1;
          transition: 0.5s;

          .carret {
            margin-bottom: -1.1rem;
          }
          .desc {
            background-color: white;
            padding: 1rem 0.5rem;
            border-radius: 12px;
          }
        }
        .user {
          width: 100%;
          height: 250px;
          object-fit: cover;
          border-radius: 12px;
        }

        &:hover {
          transform: rotate(2.5deg) scale(1.2);
          z-index: 3;
          &.rg {
            transform: rotate(-2.5deg) scale(1.2);
          }
          .arrow {
            z-index: 0;
            bottom: -1rem;
            opacity: 1;
            left: 60%;
            transform: translateX(-50%);
          }

          .info {
            opacity: 1;
            bottom: 1rem;
            z-index: 0;
          }
        }
      }
    }
  }
  &__play-video {
    &.detailService {
      .bg {
        width: 100%;
        height: 420px;
        object-fit: cover;
        border-radius: 24px;

        @media screen and(max-width: 1024px) {
          height: 350px;
        }
        @media screen and(max-width: $br-md) {
          height: 260px;
        }
      }

      @media screen and(max-width: 1024px) {
        .play {
          width: 60px;
          height: 60px;
        }
      }
    }

    .bg {
      width: 100%;
      height: 500px;
      object-fit: cover;
      border-radius: 32px;
      @media screen and(max-width: $br-md) {
        height: 400px;
      }
      @media screen and(max-width: $br-sm) {
        height: 350px;
      }
      @media screen and(max-width: $br-xx) {
        height: 250px;
      }
    }

    .play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 24px 154px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(7px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      animation: pulse 2s linear infinite;

      @media screen and(max-width: $br-md) {
        width: 70px;
        height: 70px;
      }
      @media screen and(max-width: $br-xx) {
        width: 50px;
        height: 50px;
      }
    }
  }
  &__card-service {
    border: 1px solid #e5eaf1;
    border-radius: 10px;
    padding: 2rem;
    background-color: white;
    transition: 0.5s;

    .left {
      display: flex;
      align-items: flex-start;
      > img {
        object-fit: contain;
      }
    }

    .cursor {
      position: absolute;
      left: 80%;
      transform: translateX(-50%);
      bottom: -5rem;
      opacity: 0;
      transition: 0.5s;
      z-index: -1;
    }

    &:hover {
      border: 1px solid white;
      box-shadow: 0px 4px 64px 0px #23286914;
      z-index: 2;

      .cursor {
        z-index: 0;
        bottom: -1rem;
        opacity: 1;
        left: 60%;
        transform: translateX(-50%);
      }
    }
  }
  &__rating-user {
    .rating {
      margin: 0 -3px;
      img {
        margin: 0 3px;
      }
    }

    .user {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
.list {
  &__normal {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__contact-list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }

      div {
        .icon {
          width: 36px;
          height: 36px;
          background: rgba(240, 137, 156, 0.1);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  &__menu-footer {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.clamp {
  &__md-3 {
    @media screen and(max-width: $br-md) {
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.text {
}

.images {
  &__preview-sevice {
    width: 100%;
    height: 332px;
    border-radius: 24px;
    object-fit: cover;

    @media screen and(max-width: $br-md) {
      height: 250px;
    }

    &.left {
      transform: rotate(-1.55deg);
    }
    &.rght {
      transform: rotate(2.27deg);
    }
  }
  &__bg-not {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__detail-project {
    width: 100%;
    object-fit: cover;
  }
  &__user-contact {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &__bg-contact {
    position: absolute;
    right: 0;
    top: 0;
    object-fit: cover;
  }
  &__faq-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    @media screen and(max-width: 1024px) {
      max-width: 500px;
    }
  }
  &__project {
    width: 100%;
    height: 277px;
    border-radius: 16px;
    object-fit: cover;
    transform: rotate(-0.89deg);

    &.lf {
      transform: rotate(2.33deg);
    }
  }
  &__head-wrap {
    position: absolute;
    right: 0;
    top: 0;

    @media screen and(max-width: 1024px) {
      max-width: 540px;
    }
  }
}

.btn {
}

.path {
}

.list {
  &__detail-price {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.line {
}

.pt {
  &__100 {
    padding-top: 100px;
    @media screen and(max-width: $br-md) {
      padding-top: 61px;
    }
  }
}

.p {
  &__4 {
    padding: 4rem;
  }
}

.px {
  &-xx-10 {
    @media screen and(max-width: $br-xx) {
      padding: 0 10px;
    }
  }
}

.mt {
  &__min {
    &-17 {
      margin-top: -17rem;

      @media screen and(max-width: $br-xs) {
        margin-top: -14rem;
      }
      @media screen and(max-width: $br-xxs) {
        margin-top: -13rem;
      }
    }
  }
  &__10 {
    margin-top: 10rem;
  }
  &__100 {
    margin-top: 100px;
  }
  &__5 {
    margin-top: 5rem;

    &.md-0 {
      @media screen and(max-width: $br-md) {
        margin-top: 0rem;
      }
    }
  }
  &__10 {
    margin-top: 10rem;

    &.lg-0 {
      @media screen and(max-width: $br-lg) {
        margin-top: 0rem;
      }
    }
  }
  &__18 {
    margin-top: 18rem;

    &.md-0 {
      @media screen and(max-width: $br-md) {
        margin-top: 0rem;
      }
    }
  }
}

.hr {
  &__footer {
    border-top: 1px solid #322f37;
  }
  &__line-contact {
    border-top: 1px solid #d9d9d9;
  }
}

.mb {
  &__min {
    &-3 {
      margin-bottom: -3rem;
      @media screen and(min-width:  1771px) {
        margin-bottom: 0;
      }
      @media screen and(max-width:  $br-md) {
        margin-bottom: 1rem;
      }
    }
  }
  &__4 {
    margin-bottom: 4rem;
  }
  &__5 {
    margin-bottom: 5rem;
  }

  &__md-2 {
    @media screen and(max-width: $br-md) {
      margin-bottom: 1.5rem;
    }
  }
}

// typography

.text {
  &__163 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 100px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 90px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 65px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 50px !important;
      }
    }
  }

  &__100 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 80px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 70px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }
  &__150 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 120px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 100px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }

  &__200 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 150px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 125px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-mm) {
        font-size: 100px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 85px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 75px !important;
      }
    }
  }

  &__77 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }
  }

  &__80 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 55px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 65px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }
  }

  &__70 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 60px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 35px !important;
      }
    }
  }

  &__65 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 45px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 28px !important;
      }
    }
  }

  &__60 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 30px !important;
      }
    }
  }

  &__50 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 25px !important;
      }
    }
  }

  &__25 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__45 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 24px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 16px !important;
      }
    }
  }

  &__40 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 28px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 24px !important;
      }
    }
  }

  &__30 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 20px !important;
      }
    }
  }

  &__32 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 24px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 16px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }
  }

  &__28 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__35 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
  }

  &__38 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }
  }

  &__90 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 70px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 55px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xs) {
        font-size: 30px !important;
      }
    }
  }

  &__33 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 18px !important;
      }
    }
  }

  &__18 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 12px !important;
      }
    }
  }

  &__16 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 14px !important;
      }

      &-12 {
        @media screen and(max-width: 1024px) {
          font-size: 12px !important;
        }
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 10px !important;
      }

      &-12 {
        @media screen and(max-width: $br-mm) {
          font-size: 12px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }
    }
  }

  &__20 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 14px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }
  }

  &__120 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 90px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 69px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 75px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 55px !important;
      }
    }
  }

  &__26 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__22 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__12 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 10px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-xs) {
          font-size: 10px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }
  }

  &__14 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 12px !important;
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 9px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-xx) {
          font-size: 8px !important;
        }
      }
    }
  }

  &__27 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }
  }

  &__24 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }
  }

  &__25 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__58 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 45px !important;
      }
    }
  }

  &__48 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 35px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 30px !important;
      }
    }
    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 24px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 18px !important;
      }
    }
  }
}

.w {
  &__mm-100 {
    @media screen and(max-width: $br-mm) {
      width: 100%;
    }
  }
}

.wrap {
  &__flex {
    &-1024 {
      &-100 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
        }
      }

      &-50 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-90 {
        &-3 {
          @media screen and(max-width: 1024px) {
            flex-flow: wrap;
          }
          > .grid {
            @media screen and(max-width: 1024px) {
              flex: 0 0 calc(90% / 3);
              max-width: calc(90% / 3);
            }
          }
        }
      }

      &-auto {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-md {
      &-100 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-auto {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-sm {
      &-100 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-xs {
      &-100 {
        @media screen and(max-width: $br-xs) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-xs) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-mm {
      &-100 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-4 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 calc(100% / 6);
            max-width: calc(100% / 6);
          }
        }
      }
    }
  }
}

// animated
@keyframes move1 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, 14.5rem);
  }
  75% {
    transform: translate(27rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move12 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(9.8rem, 13rem);
  }
  75% {
    transform: translate(19.7rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move2 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-27rem, 0rem);
  }
  75% {
    transform: translate(-13.4rem, 14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move22 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-19.5rem, 0rem);
  }
  75% {
    transform: translate(-10rem, 13rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move3 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, -14.5rem);
  }
  75% {
    transform: translate(-13.5rem, -14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move32 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(10rem, -13rem);
  }
  75% {
    transform: translate(-10rem, -13rem);
  }
  100% {
    transform: translate(0);
  }
}
